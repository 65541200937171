
	frappe.templates['performance_feedback_history'] = `<div class="feedback-section col-xs-12">
	{% if feedback_history %}
		<div class="feedback-summary mb-5">
			{{ frappe.render_template("performance_feedback_summary", {
				"number_of_stars": 5,
				"average_rating": average_feedback_score,
				"feedback_count": feedback_history|length,
				"reviews_per_rating": reviews_per_rating
			}) }}
		</div>
	{% endif %}

	{% if can_create %}
		<div class="new-btn pb-3 text-right">
			<button class="new-feedback-btn btn btn-sm d-inline-flex align-items-center justify-content-center px-3 py-2">
				<svg class="icon icon-sm">
					<use href="#icon-add"></use>
				</svg>
				{{ __("New Feedback") }}
			</button>
		</div>
	{% endif %}

	<div class="feedback-history mb-3" >
		{% if feedback_history %}
			{% for feedback in feedback_history %}
				<div class="feedback-content p-3 d-flex flex-row mt-3" data-name="{{ feedback.name }}"  data-bs-toggle="collapse" data-bs-target="#{{ feedback.name }}" aria-expanded="true">
					<div class="reviewer-info mb-2 col-xs-3">
						<div class="row">
							<div class="col-xs-2">
								
								{{ frappe.avatar(feedback.user, "avatar-medium") }}
							</div>
							<div class="col-xs-10">
								<div class="ml-2">
									<div class="title font-weight-bold">
										{{ strip_html(feedback.reviewer_name) }}
									</div>
									{% if feedback.reviewer_designation %}
										<div class="small text-muted">
											{{ strip_html(feedback.reviewer_designation) }}
										</div>
									{% endif %}
								</div>
							</div>
						</div>
					</div>

					<div class="reviewer-feedback col-xs-6">
						<div class="rating">
							{{ frappe.render_template("rating", {
								"number_of_stars": 5,
								"average_rating": feedback.total_score,
								"for_summary": false
							}) }}
						</div>
						<div class="feedback my-3">
							{{ feedback.feedback }}
						</div>
					</div>

					<div class="feedback-info col-xs-3 d-flex flex-row justify-content-end align-items-baseline">
						<div class="time small text-muted mr-2">
							{{ frappe.datetime.comment_when(feedback.added_on) }}
						</div>
						<a href="{{ frappe.utils.get_form_link('Employee Performance Feedback', feedback.name) }}" title="{{ __("Open Feedback") }}">
							<svg class="icon icon-sm">
								<use href="#icon-link-url" class="like-icon"></use>
							</svg>
						</a>
						<a href="javascript:;" class="ms-auto fullwidth_btn" data-toggle="tooltip" title="Expand"> <i class="ph ph-arrows-out-simple"></i> </a>
					</div>
				</div>

				<div class="grid-field collapse" id = "{{ feedback.name }}">
					<div class="form-grid-container">
						<div class="form-grid">
							<!-- Table Header -->
							<div class="grid-heading-row">
								<div class="grid-row">
									<div class="data-row row">
										<div class="row-index sortable-handle col">
											<span>No.</span>
										</div>
										<div class="col grid-static-col col-xs-6" data-fieldname="criteria">
											<div class="static-area ellipsis">Criteria</div>
										</div>
										<div class="col grid-static-col col-xs-2 text-right" data-fieldname="per_weightage">
											<div class="static-area ellipsis">Weightage (%)</div>
										</div>
										<div class="col grid-static-col col-xs-2 text-right" data-fieldname="rating_percentage">
											<div class="static-area ellipsis">Rating (out of 5)</div>
										</div>
									</div>

								</div>
							</div>

							<!-- Feedback Ratings -->
							{% if feedback.feedback_ratings %}
								{% for rating in feedback.feedback_ratings %}
									<div class="grid-row">
										<div class="data-row row">
											<div class="row-index sortable-handle col">
												<span> {{ rating._index + 1}}</span>
											</div>
											<div class="col grid-static-col col-xs-6" data-fieldname="criteria">
												<div class="static-area ellipsis">{{ rating.criteria }}</div>
											</div>
											<div class="col grid-static-col col-xs-2 text-right" data-fieldname="per_weightage">
												<div class="static-area ellipsis">{{ rating.per_weightage }}</div>
											</div>
											<div class="col grid-static-col col-xs-2 text-right" data-fieldname="rating_out_of_5">
												<div class="static-area ellipsis">{{ rating.rating_percentage }}</div>
											</div>
										</div>
									</div>
								{% endfor %}
							{% endif %}
						</div>
					</div>
				</div>

			{% endfor %}
		{% else %}
			<div class="no-feedback d-flex flex-col justify-content-center align-items-center text-muted">
				<span>{{ __("This employee has not received any feedback yet") }}</span>
			</div>
		{% endif %}
	</div>
</div>
`;
